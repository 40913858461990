import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  // apiUrl = 'http://localhost:8081';
  apiUrl = 'https://health-press-api.yuzudigital.com';

  appVersion = '1.0.0';

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    // private iab: InAppBrowser
  ) {
    console.log('Hello RestProvider Provider');
  }

  postAPI(path, param: any) {
    return new Promise((resolve, reject) => {
      this.getUserProfile().then((userData: any) => {
        const header = new HttpHeaders({
          Authorization: 'Bearer ' + userData.token
        });

        this.http.post(this.apiUrl + path, param, { headers: header })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });

    });

  }

  login(path, param: any) {
    return new Promise((resolve, reject) => {

      this.http.post(this.apiUrl + path, param, {})
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  register(path, param: any) {
    return new Promise((resolve, reject) => {

      this.http.post(this.apiUrl + path, param, {})
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  attach(path, param: any) {
    return new Promise((resolve, reject) => {

      this.http.post(this.apiUrl + path, param, {})
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  putAPI(path, param: any) {
    return new Promise((resolve, reject) => {
      this.getUserProfile().then((userData: any) => {
        const header = new HttpHeaders({
          Authorization: 'Bearer ' + userData.token
        });

        this.http.put(this.apiUrl + path, param, { headers: header })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });

    });

  }

  deleteAPI(path) {
    return new Promise((resolve, reject) => {
      this.getUserProfile().then((userData: any) => {
        const header = new HttpHeaders({
          Authorization: 'Bearer ' + userData.token
        });

        this.http.delete(this.apiUrl + path, { headers: header })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });

    });

  }

  getAPI(path) {
    return new Promise((resolve, reject) => {
      this.getUserProfile().then((userData: any) => {
        const header = new HttpHeaders({
          Authorization: 'Bearer ' + userData.token
        });
        // header.append('Content-type', 'json/data; charset=utf-8');
        // header.set('Content-type', 'json/data; charset=utf-8');
        // if (userData) {
        //   header.set('Authorization', 'Bearer ' + userData.token);
        // }
        this.http.get(this.apiUrl + path, { headers: header })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
    });

  }

  getProvinceAPI() {
    return new Promise((resolve, reject) => {
      this.getUserProfile().then((userData: any) => {
        // const header = new HttpHeaders({
        //   Authorization: 'Bearer ' + userData.token
        // });
        // header.append('Content-type', 'json/data; charset=utf-8');
        // header.set('Content-type', 'json/data; charset=utf-8');
        // if (userData) {
        //   header.set('Authorization', 'Bearer ' + userData.token);
        // }
        this.http.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json', { })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
    });

  }

  getExternalAPI(path) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + path)
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  async alert(msg) {
    const alertPromp = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [
        {
          text: 'ตกลง',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await alertPromp.present();

  }

  async alertAndGo(msg, page) {
    const alertPromp = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [
        {
          text: 'ตกลง',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.router.navigate([page]);
          }
        }
      ]
    });
    await alertPromp.present();

  }

  async alertAndGoWithValue(msg, page, value) {
    const alertPromp = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [
        {
          text: 'ตกลง',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.router.navigate([page, value]);
          }
        }
      ]
    });
    await alertPromp.present();

  }

  getUserProfile() {
    return new Promise((resolve, reject) => {
      this.storage.get('health_user').then(user => {
        resolve(user);
      });
    });
  }

  getUpdatedUserProfile() {
    return new Promise((resolve, reject) => {
      this.storage.get('health_user').then(user => {
        const header = new HttpHeaders({
          Authorization: 'Bearer ' + user.token
        });
        // header.append('Content-type', 'json/data; charset=utf-8');
        // header.set('Content-type', 'json/data; charset=utf-8');
        // if (userData) {
        //   header.set('Authorization', 'Bearer ' + userData.token);
        // }
        console.log(user)
        this.http.get(this.apiUrl + '/users/'+ user.user.id, { headers: header })
          .subscribe(res => {

            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
      });
  }

  getAppVersion() {
    return this.appVersion;
  }

  setStorage(key, value) {
    return new Promise((resolve, reject) => {
      this.storage.set(key, value).then(saved => {
        resolve(saved);
      });
    });

  }

  getStorage(key) {
    return new Promise((resolve, reject) => {
      this.storage.get(key).then(val => {
        resolve(val);
      });
    });

  }

  removeStorage(key) {
    return new Promise((resolve, reject) => {
      this.storage.remove(key).then(val => {
        resolve(val);
      });
    });

  }

  // phoneCall(tel) {
  //   this.iab.create('tel:' + tel);
  // }

  // openGoogleMap(lat, lng) {
  //   this.iab.create('https://maps.google.com/?q=' + lat + ',' + lng, '_system');
  // }

  // openUrl(url) {
  //   this.iab.create(url, '_system');
  // }

  // async alertAndCloseApp(msg, platform) {
  //   const alertPromp = await this.alertCtrl.create({
  //     header: 'Alert',
  //     message: msg,
  //     buttons: [
  //       {
  //         text: 'ตกลง',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Cancel clicked');
  //           if (platform === 'VERSION_ANDROID') {
  //             this.openUrl('https://play.google.com/store/apps/details?id=com.yuzudigital.aranyik');
  //           }
  //           this.closeApp();
  //         }
  //       }
  //     ]
  //   });
  //   await alertPromp.present();

  // }

  closeApp() {
    this.platform.backButton.subscribeWithPriority(999999, () => {
      navigator['app'].exitApp();
      // or trigger any action you want to achieve
    }); //Amended missing a closing bracket
  }

}
