import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Events } from './services/events.service';
import { RestService } from './services/rest.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private storage: Storage,
    private router: Router,
    private events: Events,
    private restApi: RestService,
  ) {
    this.initializeApp();
     events.subscribe('user:login', () => {
      // user and time are the same arguments passed in `events.publish(user, time)`
      this.storage.get('health_user').then(user => {
        if (user) {
          // this.userData = user;
          this.router.navigate(['/tabs/home']);
          // this.router.navigate(['/report']);
        }
      });
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.checkIsLogin();

    });
  }

  checkIsLogin() {
    this.restApi.getUserProfile().then((user: any) => {
      console.log(user);
      if (user) {
        // if (user.userType === 'USER') {
          this.router.navigateByUrl('/tabs/home');
        // }
      } else {
        this.router.navigateByUrl('/login');
      }

    });
  }
}
